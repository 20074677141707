<template>
    <div class="chatbot-container">
        <div class="messages-container" ref="messagesContainer">
            <div v-for="(message, index) in messages" :key="index" :class="['message', message.type]">
                <!-- User messages are on the left -->
                <span v-if="message.type === 'user'" class="user-message">{{ message.text }}</span>
                <!-- Bot messages are on the right -->
                <span v-else v-html="message.text" class="bot-message"></span>
            </div>
            <!-- Spinner when processing API request -->
            <div v-if="isLoading" class="spinner-container">
                <div class="spinner"></div>
            </div>
        </div>
        <div class="input-container">
            <input v-model="animatedInput" type="text" placeholder="Type your question or use the mic..."
                :disabled="isLoading" @keyup.enter="sendMessage" class="input-field" />
            <button :class="['mic-btn', micState === 'listening' ? 'listening' : '']" @click="handleMicClick"
                :disabled="isMicDisabled">
                <span :class="micIcon"></span>
            </button>
            <button class="send-btn" @click="sendMessage" :disabled="isLoading">
                Send
            </button>
        </div>
    </div>
</template>

<script>
import { ref, computed, onMounted, watch } from "vue";
import { GoogleGenerativeAI } from "@google/generative-ai";
import DOMPurify from "dompurify";

export default {
    name: "ChatBotComponent",
    props: {
        profileName: {
            type: String,
            required: true,
        },
        language: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const messages = ref([
            {
                text:
                    props.language === "german"
                        ? `Hallo ${props.profileName}! Wie kann ich Ihnen helfen?`
                        : `Hello ${props.profileName}! How can I assist you?`,
                type: "bot",
            },
        ]);
        const userInput = ref("");
        const animatedInput = ref(""); // Gradual animation for text input
        const isLoading = ref(false);
        const micState = ref("idle"); // 'idle', 'listening'
        const isMicDisabled = ref(false);
        const messagesContainer = ref(null);
        const projectContext = ref("");
        let recognition = null; // Web Speech API instance
        let speechTimeout = null; // Timeout for detecting silence

        const genAI = new GoogleGenerativeAI("YOUR_API_KEY");
        const models = ["gemini-1.5-flash", "gemini-1.5-flash-8b", "gemini-1.4-flash-002"];

        watch(messages, () => {
            if (messagesContainer.value) {
                messagesContainer.value.scrollTop = messagesContainer.value.scrollHeight;
            }
        });

        const loadProjectContext = async () => {
            try {
                const response = await fetch("/pia_context.md");
                if (!response.ok) throw new Error("Failed to load pia_context.md");
                projectContext.value = await response.text();
            } catch (error) {
                console.error("Error loading pia_context.md:", error);
            }
        };

        const sendMessage = async () => {
            if (!userInput.value.trim()) return;

            const userMessage = userInput.value.trim();
            messages.value.push({ text: userMessage, type: "user" });
            userInput.value = "";
            animatedInput.value = "";
            isLoading.value = true;

            try {
                const queryWithInstruction = `
                    First read the following file and use it as a reference to answer the query:
                    --------------------
                    ${projectContext.value}
                    --------------------
                    User's Question: ${userMessage}
        
                    Instructions: Respond in HTML format for user-facing guidance. Use **bold**, *italic*, or <u>underline</u> styles. Respond in "language: ${props.language}".
                `;

                let botReply = "<p>An error occurred. Please try again later.</p>";

                for (const modelName of models) {
                    try {
                        const model = genAI.getGenerativeModel({ model: modelName });
                        const { response } = await model.generateContent([{ text: queryWithInstruction }]);

                        botReply =
                            response?.candidates?.[0]?.content?.parts?.[0]?.text ||
                            "<p>Sorry, I could not understand that.</p>";
                        break; // Exit loop if successful
                    } catch (error) {
                        console.warn(`Error with model ${modelName}: ${error.message}`);
                    }
                }

                botReply = botReply.replace(/^```html\s*/, "").replace(/```$/, "");
                messages.value.push({ text: DOMPurify.sanitize(botReply), type: "bot" });
            } catch (error) {
                console.error("Error calling Gemini API:", error);
                messages.value.push({
                    text: "<p>An error occurred. Please try again later.</p>",
                    type: "bot",
                });
            } finally {
                isLoading.value = false;
            }
        };

        const handleMicClick = async () => {
            if (!("webkitSpeechRecognition" in window || "SpeechRecognition" in window)) {
                alert("Speech Recognition API not supported in your browser.");
                return;
            }

            if (micState.value === "idle") {
                try {
                    await navigator.mediaDevices.getUserMedia({ audio: true });
                    startListening();
                } catch (error) {
                    console.error("Microphone access denied:", error);
                    isMicDisabled.value = true;
                }
            } else {
                stopListening();
            }
        };

        const startListening = () => {
            micState.value = "listening";
            recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
            recognition.lang = props.language === "german" ? "de-DE" : "en-US";
            recognition.continuous = true;

            recognition.onresult = (event) => {
                clearTimeout(speechTimeout);
                let transcript = Array.from(event.results)
                    .map((result) => result[0].transcript)
                    .join(" ");
                if (transcript !== "") {
                    transcript = " " + transcript;
                }
                animateTextWriting(transcript);
                resetSpeechTimeout();
            };

            recognition.onerror = (event) => {
                console.error("Speech recognition error:", event.error);
                stopListening();
            };

            recognition.onend = () => {
                if (micState.value === "listening") {
                    stopListening();
                }
            };

            recognition.start();
            resetSpeechTimeout();
        };

        const resetSpeechTimeout = () => {
            clearTimeout(speechTimeout);
            speechTimeout = setTimeout(() => {
                stopListening();
            }, 4000); // Stop listening after 4 seconds of silence
        };

        const stopListening = () => {
            if (recognition) {
                recognition.stop();
                recognition = null;
            }
            micState.value = "idle";
        };

        const animateTextWriting = (text) => {
            let index = 0;
            const interval = setInterval(() => {
                if (index < text.length) {
                    animatedInput.value += text[index];
                    index++;
                } else {
                    clearInterval(interval);
                }
            }, 50); // Gradual typing effect (50ms per character)
        };

        const micIcon = computed(() =>
            micState.value === "listening" ? "mdi mdi-microphone" : "mdi mdi-microphone-outline"
        );

        onMounted(loadProjectContext);

        return {
            messages,
            userInput,
            animatedInput,
            sendMessage,
            micState,
            handleMicClick,
            micIcon,
            isMicDisabled,
        };
    },
};
</script>


<style scoped>
.chatbot-container {
    display: flex;
    flex-direction: column;
    height: 600px;
    border-radius: 20px;
    background: linear-gradient(145deg, #ffffff, #f0f0f0);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background: #fdfdfd;
    border-radius: 12px;
    scrollbar-width: thin;
    scrollbar-color: #4caf50 #f0f0f0;
}

.messages-container::-webkit-scrollbar {
    width: 8px;
}

.messages-container::-webkit-scrollbar-thumb {
    background: #4caf50;
    border-radius: 4px;
}

.mic-btn {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    background-color: #4caf50;
    color: white;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
    cursor: pointer;
}

.mic-btn.listening {
    animation: densePulse 1.2s infinite;
    background: radial-gradient(circle, rgba(76, 175, 80, 1) 0%, rgba(76, 175, 80, 0.6) 50%, rgba(76, 175, 80, 0) 100%);
}

@keyframes densePulse {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0.7);
    }

    50% {
        box-shadow: 0 0 20px 10px rgba(76, 175, 80, 0.3);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(76, 175, 80, 0);
    }
}

.message {
    padding: 12px 16px;
    margin: 10px 0;
    border-radius: 12px;
    font-size: 16px;
    max-width: 75%;
    line-height: 1.4;
    animation: fadeIn 0.5s ease;
}

.message.bot {
    background-color: #f1f1f1;
    color: #333;
    align-self: flex-end;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.message.user {
    background-color: #4caf50;
    color: white;
    align-self: flex-start;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
}

.input-container {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 16px;
    background: #ffffff;
    border-top: 1px solid #ddd;
}

.input-field {
    flex-grow: 1;
    padding: 12px 16px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 25px;
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.input-field:focus {
    border-color: #4caf50;
    box-shadow: 0px 0px 8px rgba(76, 175, 80, 0.2);
}

.send-btn {
    background: linear-gradient(145deg, #4caf50, #45a047);
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.3s ease, background-color 0.3s ease;
    width: 70px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message.bot strong {
    font-weight: bold;
}

.message.bot em {
    font-style: italic;
}

.message.bot u {
    text-decoration: underline;
}

.send-btn:hover {
    transform: scale(1.1);
    background: #42a647;
}

.send-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.spinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #4caf50;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Responsive styling for medium screens */
@media (max-width: 768px) {
    .chatbot-container {
        height: 100%;
        width: 100%;
        border-radius: 0;
    }

    .messages-container {
        padding: 10px;
    }

    .message {
        font-size: 14px;
        margin: 8px 0;
        padding: 10px;
    }

    .input-container {
        gap: 8px;
        padding: 10px;
    }

    .input-field {
        font-size: 14px;
        padding: 10px 14px;
    }

    .send-btn {
        width: 60px;
        height: 40px;
        font-size: 14px;
    }

    .mic-btn {
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
}

/* Responsive styling for small screens */
@media (max-width: 480px) {
    .chatbot-container {
        height: 100%;
        width: 100%;
        border-radius: 0;
        padding: 5px;
    }

    .messages-container {
        padding: 5px;
    }

    .message {
        font-size: 12px;
        margin: 6px 0;
        padding: 8px;
    }

    .input-container {
        gap: 5px;
        padding: 5px;
    }

    .input-field {
        font-size: 12px;
        padding: 8px 10px;
    }

    .send-btn {
        width: 50px;
        height: 35px;
        font-size: 12px;
    }

    .mic-btn {
        width: 35px;
        height: 35px;
        font-size: 16px;
    }
}
</style>