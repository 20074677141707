<template>
    <div class="create-edit-group">
        <h2>{{ title }}</h2>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CreateEditGroup',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.create-edit-group {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>