<template>
  <div>
    <!-- Profile Card Section -->
    <div class="profile-card-mobile">
      <div class="profile-content-mobile">
        <div class="text-section">
          <h2 class="profile-greeting-mobile">Hi {{ userProfile?.name }}</h2>
          <p class="profile-info-mobile">{{ userProfile?.job }} / München, DE</p>
        </div>
        <div class="button-section">
          <v-btn @click="toggleEditMode" :color="isEditMode ? 'green' : 'black'" outlined class="edit-done-btn">
            <v-icon class="mr-2">{{ isEditMode ? 'mdi-check' : 'mdi-pencil' }}</v-icon>
            {{ isEditMode ? 'Done' : 'Edit' }}
          </v-btn>
          <button @click="createPost" class="btn-create-post-mobile">+ Create New Post</button>
        </div>
      </div>
    </div>

    <!-- Team Members Section -->
    <div class="team-members-card">
      <h3 class="team-title">Team Members</h3>
      <div class="team-members-slider">
        <div class="team-member-slide" v-for="(member, i) in teamMembers" :key="i" @click="props.itemClicked(member)">
          <div class="avatar">
            <div class="initials">{{ member.initials }}</div>
          </div>
          <div class="name">{{ member.name }}</div>
          <!-- <div class="position">{{ member.position }}</div> -->
          <div class="status">
            <span class="status-chip">{{ member.status }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Upcoming Events Section -->
    <div class="upcoming-events-mobile">
      <h3 class="upcoming-title-mobile">Upcoming Events</h3>
      <div class="upcoming-events-list-mobile-slider" v-if="upcomingEvents?.length !== 0">
        <div class="event-item-mobile" v-for="(event, i) in upcomingEvents" :key="i">
          <div class="event-details-mobile">
            <div class="event-title-mobile">{{ event.title }}</div>
            <div class="event-date-mobile">{{ event.date }}</div>
            <div class="event-description-mobile">{{ event.description }}</div>
          </div>
          <v-card-actions
            v-if="isEditMode"
            style="border-bottom-left-radius: 16px; border-bottom-right-radius: 16px; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)"
          >
            <v-btn icon color="green" class="mr-2" @click="editItem(event)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-2" @click="deleteItem(event)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
      <div
        v-else
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(135deg, #f3e7ff, #e0eafc);
          padding: 1rem;
          border-radius: 16px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          color: #2d3748;
        "
      >
        <v-icon style="margin-right: 0.5rem">mdi-calendar-remove</v-icon>
        <span>No upcoming events in future</span>
      </div>
    </div>

    <!-- News Section -->
    <div class="news-section-mobile">
      <h3 class="news-title-mobile">News</h3>
      <div class="news-list-mobile" v-if="newsItems?.length !== 0">
        <div v-for="(news, i) in newsItems" :key="i" @click="props.itemClicked(news)">
          <div class="news-card-mobile">
            <div class="news-image-wrapper">
              <v-img :src="news.image" class="news-image-mobile" />
            </div>
            <div class="news-content-mobile">
              <div class="news-card-title-mobile">{{ news.title }}</div>
              <div class="news-card-subtitle-mobile">{{ news.description }}</div>
            </div>
          </div>
          <v-card-actions
            v-if="isEditMode"
            style="border-bottom-left-radius: 16px; border-bottom-right-radius: 16px; box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1)"
          >
            <v-btn icon color="green" class="mr-2" @click="editItem(news)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon color="red" class="mr-2" @click="deleteItem(news)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
      <div
        v-else
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(135deg, #f3e7ff, #e0eafc);
          padding: 1rem;
          border-radius: 16px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          color: #2d3748;
        "
      >
        <span>No Upcoming News or Announcements</span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, defineProps, defineEmits } from 'vue'
  import { useRouter } from 'vue-router'

  // Props
  const props = defineProps({
    newsItems: Array,
    teamMembers: Array,
    upcomingEvents: Array,
    userProfile: Object,
    itemClicked: Function,
    editItem: Function,
    deleteItem: Function,
    isEditModeParent: Boolean,
  })

  const router = useRouter()
  const emit = defineEmits(['update:isEditModeParent'])
  const isEditMode = ref(props.isEditModeParent)
  const toggleEditMode = () => {
    isEditMode.value = !isEditMode.value
    emit('update:isEditModeParent', isEditMode.value)
  }

  const createPost = () => {
    router.push({ name: 'CreateEditInfoItem' }).catch(err => console.error(err))
  }
</script>

<style scoped>
  /* **************MOBILE VIEW********************************* */

  /* Profile Section */
  .profile-card-mobile {
    background: linear-gradient(90deg, #3b82f6 0%, #9333ea 100%);
    padding: 1.5rem;
    border-radius: 16px;
    color: white;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .profile-card-mobile::before,
  .profile-card-mobile::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }

  .profile-card-mobile::before {
    width: 150px;
    height: 150px;
    top: -40px;
    left: -50px;
  }

  .profile-card-mobile::after {
    width: 200px;
    height: 200px;
    bottom: -60px;
    right: -70px;
  }

  .profile-content-mobile {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-section {
    text-align: left;
  }

  .profile-greeting-mobile {
    font-size: 1.5rem;
    margin: 0;
    font-weight: bold;
  }

  .profile-info-mobile {
    font-size: 1rem;
    margin: 0.5rem 0;
    font-weight: 500;
  }

  .button-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .edit-done-btn {
    order: 1; /* Ensure this button stays on the left */
  }

  .btn-create-post-mobile {
    order: 2; /* Ensure this button stays on the right */
    background-color: white;
    color: darkblue;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-weight: bold;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  /* Team Members Section */
  .team-members-card {
    background-color: white;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }

  .team-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
  }

  .team-members-slider {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    padding-bottom: 1rem;
  }

  .team-member-slide {
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    margin-right: 1rem;
    flex-shrink: 0;
    width: 100px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .avatar {
    width: 60px;
    height: 60px;
    margin: 0 auto 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #5a67d8;
    color: white;
    font-weight: bold;
    border-radius: 50%;
    font-size: 1rem;
  }

  .name {
    font-weight: bold;
    font-size: 0.9rem;
    padding: 3px;
    text-transform: capitalize;
    color: #333;
    margin-bottom: 0.5rem;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .position {
    font-size: 0.75rem;
    color: #555;
    margin-bottom: 0.5rem;
  }

  .status {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .status-chip {
    display: inline-block;
    background-color: #cce7ff;
    color: #007bff;
    padding: 0.25rem 0.5rem;
    border-radius: 12px;
    font-size: 0.75rem;
  }

  /* Upcoming Events Section */
  .upcoming-events-mobile {
    background-color: white;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }

  .upcoming-title-mobile {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  .upcoming-events-list-mobile-slider {
    display: flex;
    overflow-x: auto;
    scroll-behavior: smooth;
    gap: 0.75rem;
    padding-bottom: 1rem;
  }

  .upcoming-events-list-mobile-slider::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar */
  }

  .event-item-mobile {
    flex: 0 0 calc(45% - 0.5rem);
    /* Two items per row */
    background: linear-gradient(135deg, #f3e7ff, #e0eafc);
    padding: 1rem;
    max-width: 12rem;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #2d3748;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .event-details-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
  }

  .event-title-mobile {
    font-weight: bold;
    font-size: 1rem;
    color: #2d3748;
    line-height: 1.2;
  }

  .event-date-mobile {
    font-size: 0.875rem;
    color: #718096;
    line-height: 1.1;
  }

  .event-description-mobile {
    font-size: 0.8rem;
    color: #4a5568;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* News Section */
  .news-section-mobile {
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #82b1ff #f1f1f1;
  }

  .news-title-mobile {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
    text-align: center;
  }

  .news-list-mobile {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #82b1ff #f1f1f1;
  }

  .news-card-mobile {
    display: flex;
    background: #f9f9f9;
    padding: 1rem;
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    gap: 1rem;
    align-items: center;
  }

  .news-image-wrapper {
    width: 100px;
    height: 100px;
    flex-shrink: 0;
  }

  .news-image-mobile {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }

  .news-content-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .news-card-title-mobile {
    font-weight: bold;
    font-size: 1.1rem;
    color: #222;
    line-height: 1.4;
  }

  .news-card-subtitle-mobile {
    font-size: 0.9rem;
    color: #555;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
  }

  /* Media Queries for Members Section */
  @media (max-width: 640px) {
    .team-member-slide {
      width: 80px;
      padding: 0.6rem;
      font-size: 0.8rem;
      margin-right: 0.5rem;
    }

    .avatar {
      width: 50px;
      height: 50px;
      font-size: 0.8rem;
    }

    .name {
      font-size: 0.8rem;
      margin-bottom: 0.4rem;
    }

    .position {
      font-size: 0.7rem;
      margin-bottom: 0.4rem;
    }

    .status-chip {
      font-size: 0.65rem;
    }

    .event-title-mobile {
      font-size: small;
    }

    .event-date-mobile {
      font-size: smaller;
    }

    .news-card-title-mobile {
      font-size: 1rem;
    }

    .news-card-subtitle-mobile {
      font-size: smaller;
    }
  }

  @media (max-width: 400px) {
    .team-member-slide {
      width: 70px;
      padding: 0.4rem;
      font-size: 0.7rem;
      margin-right: 0.3rem;
    }

    .avatar {
      width: 40px;
      height: 40px;
      font-size: 0.7rem;
    }

    .name {
      font-size: 0.7rem;
      margin-bottom: 0.3rem;
    }

    .position {
      font-size: 0.6rem;
      margin-bottom: 0.3rem;
    }

    .status-chip {
      font-size: 0.6rem;
    }

    .news-card-title-mobile {
      font-size: smaller;
    }

    .event-title-mobile {
      font-size: smaller;
    }
  }
</style>
