<template>
  <div class="time-slots">
    <!-- Legend Section -->
    <div class="legend">
      <v-chip class="legend-item free" label outlined>
        <v-icon class="legend-icon" left>mdi-calendar-check</v-icon>
        <span class="legend-text">{{ $t('booking.available') }}</span>
      </v-chip>
      <v-chip class="legend-item partial" label outlined>
        <v-icon class="legend-icon" left>mdi-calendar</v-icon>
        <span class="legend-text">{{ $t('booking.partially-booked') }}</span>
      </v-chip>
      <v-chip class="legend-item booked" label outlined
        style="background: linear-gradient(45deg, rgba(255, 94, 87, 1) 0%, rgba(255, 160, 122, 1) 100%) !important; color: black">
        <v-icon class="legend-icon" left>mdi-calendar-remove</v-icon>
        <span class="legend-text">{{ $t('booking.booked') }}</span>
      </v-chip>
    </div>

    <div class="timeline-grid">
      <div v-for="(slot, index) in timeSlots" :key="index" class="time-slot-container">
        <div class="timeline-label">{{ timeLabels[index] }}</div>

        <v-tooltip top :value="hoveredSlotIndex === index && slot.bookings.length > 0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="time-slot" v-bind="attrs" v-on="on" @mouseover="showTooltip(slot, index, $event)"
              @mouseleave="hideTooltip">
              <div v-for="quarter in 4" :key="quarter" class="quarter-slot"
                :style="getQuarterFillStyle(index, quarter)"></div>
            </div>
          </template>

          <div v-if="slot.bookings.length > 0" class="compact-tooltip">
            <div v-for="(booking, idx) in slot.bookings" :key="idx" class="tooltip-booking">
              <p class="tooltip-name">{{ booking.name }}</p>
              <p class="tooltip-time">{{ booking.start }} - {{ booking.end }}</p>
            </div>
          </div>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  props: ['selectedDate', 'bookings'],
  data() {
    return {
      screenWidth: window.innerWidth,
      hoveredSlotIndex: null,
      tooltipPosition: { top: '0px', left: '0px' },
      timeLabels: ['06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00'],
      timeSlots: Array.from({ length: 14 }, () => ({
        filled: [false, false, false, false],
        bookings: [],
      })),
    }
  },
  watch: {
    bookings: {
      handler: 'calculateFilling',
      immediate: true,
    },
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    calculateFilling() {
      const { bookings, timeLabels, timeSlots } = this

      // Reset time slots
      timeSlots.splice(
        0,
        timeSlots.length,
        ...Array.from({ length: 14 }, () => ({
          filled: [false, false, false, false],
          bookings: [],
        }))
      )

      bookings.forEach(booking => {
        // Parse startTime and endTime as UTC ISO dates
        const startTime = moment.utc(booking.startTime)
        const endTime = moment.utc(booking.endTime)
        this.processRegularBooking(startTime, endTime, booking)
      })
    },
    processRegularBooking(startTime, endTime, booking) {
      const { timeLabels } = this

      // Adjust each label to UTC to match the bookings' times
      timeLabels.forEach((label, i) => {
        const slotStart = moment.utc(label, 'HH:mm')
        const slotEnd = moment.utc(slotStart).add(1, 'hour')

        if (startTime.isBefore(slotEnd) && endTime.isAfter(slotStart)) {
          this.fillSlotWithQuarters(slotStart, slotEnd, startTime, endTime, i, booking)
        }
      })
    },
    fillSlotWithQuarters(slotStart, slotEnd, startTime, endTime, index, booking) {
      const quarters = [false, false, false, false]
      const quarterDuration = 15

      for (let q = 0; q < 4; q++) {
        const quarterStart = slotStart.clone().add(q * quarterDuration, 'minutes')
        const quarterEnd = quarterStart.clone().add(quarterDuration, 'minutes')

        if (startTime.isBefore(quarterEnd) && endTime.isAfter(quarterStart)) {
          quarters[q] = true
        }
      }

      // Update the slot directly without using $set
      this.timeSlots[index].filled = quarters
      this.timeSlots[index].bookings.push({
        start: startTime.format('HH:mm'),
        end: endTime.format('HH:mm'),
        name: booking.creatorName,
      })
    },
    showTooltip(slot, index, event) {
      console.log('slot', slot)
      this.hoveredSlotIndex = index
      this.tooltipPosition = {
        top: `${event.clientY + 20}px`,
        left: `${event.clientX + 20}px`,
      }
    },
    getQuarterFillStyle(slotIndex, quarter) {
      const isFilled = this.timeSlots[slotIndex].filled[quarter - 1]
      return {
        background: isFilled ? 'linear-gradient(45deg, rgba(255, 94, 87, 1) 0%, rgba(255, 160, 122, 1) 100%)' : 'white',
        width: '25%',
        height: '100%',
        display: 'inline-block',
      }
    },
    hideTooltip() {
      this.hoveredSlotIndex = null
    },
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth
    })
  },
  beforeUnmount() {
    window.removeEventListener('resize', () => {
      this.screenWidth = window.innerWidth
    })
  },
}
</script>

<style scoped>
.legend {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 0 3px;
  padding: 8px 12px;
}

.legend-item.free {
  background-color: white;
  border: 1px solid #ddd;
  color: black;
}

.legend-item.partial {
  background: linear-gradient(90deg, white 50%, rgba(255, 94, 87, 1) 50%, rgba(255, 160, 122, 1) 100%);
  border: 1px solid #ddd;
  color: black;
}

.legend-item.booked {
  background: linear-gradient(45deg, rgba(255, 94, 87, 1) 0%, rgba(255, 160, 122, 1) 100%);
  color: white;
}

.timeline-grid {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 2px;
  align-items: center;
}

.time-slot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.timeline-label {
  text-align: left;
  font-size: 14px;
  color: #333;
  font-weight: 500;
  width: 100%;
}

.time-slot {
  display: flex;
  height: 60px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #ddd;
  transition: background-color 0.3s, border-color 0.3s ease;
  border-radius: 0px;
  overflow: hidden;
  box-sizing: border-box;
}

.quarter-slot {
  transition: background-color 0.3s ease;
}

.time-slot:hover .quarter-slot {
  background-color: #f1f6ff;
}

.tooltip {
  position: absolute;
  background-color: #fff;
  padding: 8px 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
  white-space: nowrap;
}

.compact-tooltip {
  padding: 5px 8px;
  font-size: 12px;
}

.tooltip-booking {
  padding: 4px;
  margin-bottom: 2px;
}

.tooltip-name {
  font-weight: bold;
  color: #333;
}

.tooltip-time {
  color: gray;
}

@media (max-width: 1350px) {
  .timeline-grid {
    grid-template-columns: repeat(11, 1fr);
  }
}

@media (max-width: 768px) {
  .timeline-grid {
    grid-template-columns: repeat(4, 1fr);
  }

  .time-slot {
    height: 50px;
  }
}

@media (max-width: 600px) {
  .legend-text {
    font-size: small;
    white-space: break-spaces;
    line-height: normal;
  }

  .legend-icon {
    font-size: medium;
  }
}

@media (max-width: 450px) {
  .legend-text {
    font-size: 11px;
    white-space: break-spaces;
    line-height: normal;
  }

  .legend-icon {
    font-size: small;
  }
}
</style>
