<template>
  <div>
    <!-- Table Layout for Larger Screens -->
    <v-container v-if="isLargeScreen" fluid>
      <v-table :hover="true" density="comfortable" class="elegant-table mt-4" v-if="incidents.length">
        <thead>
          <tr>
            <th class="text-center">Description</th>
            <th class="text-center">Date</th>
            <th class="text-center">Category</th>
            <th class="text-center">Location</th>
            <th class="text-center">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="incident in incidents" :key="incident.id" style="cursor: pointer" @click="handleClick(incident)">
            <td>{{ incident.description }}</td>
            <td>{{ formatDate(incident.creationTime) }}</td>
            <td>{{ incident.incidentCategoryName }}</td>
            <td>{{ incident.location.locationName }}</td>
            <td :style="getStatusStyle(incident.state)">
              {{ incident.state }}
            </td>
          </tr>
        </tbody>
      </v-table>
      <p v-if="!incidents.length" class="text-center">No incidents found.</p>
    </v-container>

    <!-- Custom Card Layout for Small Screens -->
    <v-container v-else>
      <div v-for="incident in incidents" :key="incident.id" class="incident-card-container"
        @click="handleClick(incident)">
        <div class="incident-card" @click="handleClick(incident)">
          <div class="incident-card-header">
            <div class="incident-date">
              <i class="mdi mdi-calendar mr-1"></i>
              <span>{{ formatDate(incident.creationTime, 'dddd, DD MMM YYYY') }}</span>
            </div>
            <span class="incident-status" :style="{
              backgroundColor:
                incident.state.toLowerCase() === 'open'
                  ? 'rgba(255, 0, 0, 0.1)'
                  : incident.state.toLowerCase()?.includes('done')
                    ? 'rgba(0, 128, 0, 0.1)'
                    : 'rgba(255, 165, 0, 0.1)',
              color: 'black',
            }">
              {{ incident.state }}
            </span>
          </div>

          <div class="incident-card-content">
            <div class="incident-detail">
              <i class="mdi mdi-text mr-1"></i>
              <span>{{ incident.description }}</span>
            </div>
            <div class="incident-detail">
              <i class="mdi mdi-format-list-bulleted mr-1"></i>
              <span>{{ incident.incidentCategoryName }}</span>
            </div>
            <div class="incident-detail">
              <i class="mdi mdi-map-marker mr-1"></i>
              <span>{{ incident.location.locationName }}</span>
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue'
import moment from 'moment'

export default defineComponent({
  name: 'UserIncidents',
  props: {
    incidents: {
      type: Array,
      required: true,
    },
    clickHandler: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const windowWidth = ref(window.innerWidth)

    // Update window width on resize
    const updateWidth = () => {
      windowWidth.value = window.innerWidth
    }

    const getStatusStyle = status => {
      if (status.toLowerCase() === 'open') {
        return { color: 'red' } // Dim red for open
      } else if (status.toLowerCase() === 'done') {
        return { color: 'green' } // Light green for closed
      } else {
        return { color: 'orange' } // Dim orange for other statuses
      }
    }

    onMounted(() => {
      window.addEventListener('resize', updateWidth)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth)
    })
    const isLargeScreen = computed(() => windowWidth.value > 800)

    const formatDate = (date, format = 'DD.MM.YYYY') => moment(date).format(format)

    const handleClick = incident => {
      props.clickHandler(incident)
    }

    return {
      isLargeScreen,
      formatDate,
      handleClick,
      getStatusStyle,
    }
  },
})
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

.incident-card-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.incident-card {
  font-family: 'Inter', sans-serif;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  padding: 16px;
  cursor: pointer;
}

.incident-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.incident-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.incident-date {
  font-weight: 600;
  font-size: 1rem;
  color: #444;
  display: flex;
  align-items: center;
}

.incident-status {
  background-color: #4caf50;
  color: #fff;
  font-size: 0.875rem;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.incident-card-content {
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
  display: grid;
  gap: 8px;
}

.incident-detail {
  font-size: 0.95rem;
  color: #666;
  display: flex;
  align-items: center;
}

.mr-1 {
  margin-right: 6px;
}

/* Elegant Table Styling */
.elegant-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.elegant-table thead {
  background-color: #4caf50;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.95rem;
}

.elegant-table th,
.elegant-table td {
  padding: 12px 16px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
}

.elegant-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.elegant-table tbody tr:hover {
  background-color: #f1f1f1;
}
</style>
<!-- UserIncidents Component

This component displays a list of user incidents in two different layouts depending on the screen size. 

For larger screens, it uses a table layout to present the incidents in a structured manner. The table includes columns for Description, Date, Category, Location, and Status. Each row in the table represents an incident, and clicking on a row triggers the handleClick method, which calls the clickHandler function passed as a prop. The table provides visual feedback by highlighting rows on hover and changing the background color of the status cell based on the incident state.

For smaller screens, the component switches to a card layout to ensure better readability and usability on mobile devices. Each incident is displayed as a card with sections for the date, status, description, category, and location. The status is visually distinguished with different background colors based on its state (open, done, or other). Clicking on a card also triggers the handleClick method.

The component dynamically adjusts its layout based on the screen width, which is tracked using a reactive windowWidth variable. It listens for window resize events to update the layout accordingly.

The component also provides utility methods for formatting dates and determining the style of the status text.

Overall, this component enhances the user experience by providing a responsive and interactive way to view and manage incidents, with clear visual feedback and easy navigation. -->
