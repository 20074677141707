<template>
    <div class="create-edit-role">
        <Loader :loading="showLoader" />
        <v-toolbar class="custom-toolbar mb-4" flat dense>
            <v-btn icon @click="goBack">
                <v-icon size="40">mdi-arrow-left-bold-circle</v-icon>
            </v-btn>
            <v-toolbar-title class="custom-toolbar-title">
                {{ 'Roles Detail' }}
            </v-toolbar-title>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser"
                color="black">Abort</v-btn>
            <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser"
                color="black">Update</v-btn>
        </v-toolbar>

        <!-- User Information Section -->
        <div class="user-section">
            <h2>Role Information</h2>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Role Name" v-model="user.firstName" variant="outlined" />
                </v-col>
                <v-col cols="12">
                    <v-textarea label="Description" v-model="user.description" variant="outlined" />
                </v-col>
            </v-row>
        </div>

        <!-- Application Functions Section -->
        <div class="location-section">
            <h2>Application Functions</h2>
            <v-card-text class="tile-description">
                Specify which functions this role should have permissions for.
            </v-card-text>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-switch label="Analytics" v-model="permissions.analytics" inset color="success"></v-switch>
                        <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                        <v-switch label="Booking" v-model="permissions.booking" inset color="success"></v-switch>
                        <v-row>
                            <v-col cols="auto">
                                <v-checkbox label="Availability check and booking" v-model="permissions.bookingDetails"
                                    :disabled="!permissions.booking" dense></v-checkbox>
                            </v-col>
                        </v-row>
                        <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                        <v-switch label="Displays" v-model="permissions.displays" inset color="success"></v-switch>
                        <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                        <v-switch label="Operations" v-model="permissions.operations" inset color="success"></v-switch>
                        <v-row>
                            <v-col cols="auto">
                                <v-checkbox label="Booking overview"
                                    v-model="permissions.operationsDetails.bookingOverview"
                                    :disabled="!permissions.operations" dense></v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox label="Community" v-model="permissions.operationsDetails.community"
                                    :disabled="!permissions.operations" dense></v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox label="Guests" v-model="permissions.operationsDetails.guests"
                                    :disabled="!permissions.operations" dense></v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox label="Locations" v-model="permissions.operationsDetails.locations"
                                    :disabled="!permissions.operations" dense></v-checkbox>
                            </v-col>
                        </v-row>
                        <div class="divider"></div>
                    </v-col>
                    <v-col cols="12">
                        <v-switch label="User Management" v-model="permissions.userManagement" inset
                            color="success"></v-switch>
                        <v-row>
                            <v-col cols="auto">
                                <v-checkbox label="User" v-model="permissions.userManagementDetails.user"
                                    :disabled="!permissions.userManagement" dense></v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox label="Group" v-model="permissions.userManagementDetails.group"
                                    :disabled="!permissions.userManagement" dense></v-checkbox>
                            </v-col>
                            <v-col cols="auto">
                                <v-checkbox label="Role" v-model="permissions.userManagementDetails.role"
                                    :disabled="!permissions.userManagement" dense></v-checkbox>
                            </v-col>
                        </v-row>
                        <div class="divider"></div>
                    </v-col>
                </v-row>
            </v-container>
        </div>


        <!-- Activity Section -->
        <div class="activity-section">
            <h2>Assigned users</h2>


        </div>

        <!-- Save Button -->

        <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser" color="black">Abort</v-btn>
        <v-btn size="small" class="pulse-animation" variant="tonal" @click="openCreateUser" color="black">Update</v-btn>

    </div>
</template>

<script>
import { ref, defineComponent, onMounted } from 'vue';
import { debounce } from 'lodash';
import lodash from 'lodash';
import Loader from '@/components/general/Loader.vue';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { getRoles } from '@/controllers/BackboneAPI';
import { getStoredData, getCampuses, getCampusById, isStaingOrQa } from '@/controllers/BaseController';


export default defineComponent({
    name: 'CreateEditRole',
    components: {
        Loader,
    },
    setup() {
        const showLoader = ref(true);
        const user = ref({
            roleName: '',
            description: '',
            location: '',
            isActive: false,
            activeFrom: '',
            activeUntil: '',
            roles: [],
            groups: [],
        });
        const locations = ref(['Location 1', 'Location 2', 'Test Location']);
        const roles = ref(['Employee', 'Admin', 'Test Role']);
        const originalRoles = ref([]);
        const groups = ref(['Group 1', 'Group 2', 'Test Group']);
        const originalGroups = ref([]);
        const campuses = ref([]);
        const route = useRoute();
        const isNew = computed(() => !route.params.id);
        const isEdit = computed(() => !!route.params.id);

        const permissions = ref({
            analytics: false,
            booking: false,
            bookingDetails: false,
            displays: false,
            operations: false,
            operationsDetails: {
                bookingOverview: false,
                community: false,
                guests: false,
                locations: false,
            },
            userManagement: false,
            userManagementDetails: {
                user: false,
                group: false,
                role: false,
            },
        });

        const goBack = () => {
            window.history.back();
        };

        const addRole = () => {

        };

        const addGroup = () => {

        };

        const updateUser = () => {

        };

        onMounted(async () => {
            try {
                showLoader.value = false;
                let CAMPUSES = lodash.chain(await getCampuses())
                    .filter({ active: true })
                    .orderBy('name', 'asc')
                    .value();
                if (await isStaingOrQa()) {
                    CAMPUSES = lodash.filter(CAMPUSES, (campus) => campus.name?.toLowerCase() !== 'hamburg');
                }
                campuses.value = CAMPUSES;

                locations.value = CAMPUSES.map(campus => campus.name);
                let ROLES = await getRoles();
                ROLES = lodash.chain(ROLES)
                    .filter({ active: true })
                    .orderBy('name', 'asc')
                    .value();
                roles.value = ROLES.map(role => role.name);

                if (isEdit.value) {
                    let USER = await getStoredData(route.params.id);
                    console.log(USER)
                    user.value = { ...USER };
                    user.value.location = USER.campusName;

                }
            } catch (error) {
                console.error('Failed to load data:', error);
            } finally {
                showLoader.value = false;
            }
        });

        return {
            showLoader,
            user,
            locations,
            roles,
            groups,
            goBack,
            addRole,
            addGroup,
            updateUser,
            route,
            isNew,
            isEdit,
            permissions,

        };
    },
});
</script>

<style scoped>
.create-edit-role {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.custom-toolbar {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    color: #333;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
}

.custom-toolbar-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
    color: #333;
    margin-left: 10px;
}

.toolbar-icon {
    height: 30px;
}

.user-section,
.location-section,
.activity-section,
.roles-groups-section {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

.actions {
    margin-top: 2rem;
}

h2 {
    margin-bottom: 1rem;
    font-size: 1.25rem;
    color: #333;
}

.v-row {
    gap: 1rem;
    /* Adds spacing between checkboxes */
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #e0e0e0;
    margin: 1rem 0;
}


.tile-description {
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
    color: #666;
}

.location-section {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #fff;
}

.v-switch,
.v-checkbox {
    margin-bottom: 0.5rem;
}

@media screen and (max-width: 768px) {
    .location-section {
        padding: 0.5rem;
    }

    .tile-description {
        font-size: 0.85rem;
    }
}


@media screen and (max-width: 768px) {
    h2 {
        font-size: 1.15rem;
    }

    .create-edit-user {
        padding: 0.5rem;
    }
}
</style>